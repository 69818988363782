import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};
export default function ContactsMap(props) {
const {google,center,zoom,APIKey}= props;
  return (
    <LoadScript
      googleMapsApiKey={APIKey}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
      >
        <Marker
            google={google}
            position={{
              lat: center.lat,
              lng: center.lng
            }}
          />
          <Marker /> 
      </GoogleMap>
    </LoadScript>
  )
}
