import React, { useState, useEffect} from 'react';
import Pagination from '@material-ui/lab/Pagination';
export default function ProductsList({SasToken,ProductsData, RWStorageURL}) {
    const [list, setList] = useState(false);
    const [category, setCategory] = useState(null);
    const [subCategoryNumber, setSubCategoryNumber] = useState(0);
    const [type, setType] = useState(false);
    const [typeIndex, setTypeIndex] = useState(null);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(false);
    const [paginationItems, setPaginationItems] = useState(4);
    const [paginationSize, setPaginationSize] = useState("large");

    const categoryClickHandle = (e, index) => {
        setCategory(index);
        setList(true);
        if(ProductsData[index]["categoryData"].length <= 4)
            setSubCategoryNumber(2);
        else if(ProductsData[index]["categoryData"].length <= 6)
            setSubCategoryNumber(5);
        else if(ProductsData[index]["categoryData"].length > 6)
            setSubCategoryNumber(9);
    }
    const listClickHandle = () => {
        setCategory(null);
        setType(false);
        setList(false);
        setPagination(false);
        setPage(1);
        setSubCategoryNumber(0);
    }
    const typeClickHandle = (e, index) => {
        setType(true);
        if (ProductsData[category]["categoryData"][index]["itemData"].length <=2)
                setSubCategoryNumber(2)
        else if (ProductsData[category]["categoryData"][index]["itemData"].length <5)
                setSubCategoryNumber(5)
        else if ((ProductsData[category]["categoryData"][index]["itemData"].length <=9) || window.innerWidth <(601))
                setSubCategoryNumber(9)
        else
                setSubCategoryNumber(8)
        if (ProductsData[category]["categoryData"][index]["itemData"].length > paginationItems)
                setPagination(true);               
        setTypeIndex(index);

    }
    const typeBackClickHandle = () => {
        setType(false);
        setTypeIndex(null);
        setPage(1);
        setPagination(false);
        if(ProductsData[category]["categoryData"].length <= 4)
            setSubCategoryNumber(2);
        else if(ProductsData[category]["categoryData"].length <= 6)
            setSubCategoryNumber(5);
        else if(ProductsData[category]["categoryData"].length > 6)
            setSubCategoryNumber(9);
    }
    const handleChange = (event, value) => {
        if(ProductsData[category]["categoryData"][typeIndex]["itemData"].slice((value - 1) * paginationItems, value * paginationItems).length <=2)
            setSubCategoryNumber(2);
        else if(ProductsData[category]["categoryData"][typeIndex]["itemData"].slice((value - 1) * paginationItems, value * paginationItems).length <=4)
            setSubCategoryNumber(5);
        else if((ProductsData[category]["categoryData"][typeIndex]["itemData"].slice((value - 1) * paginationItems, value * paginationItems).length <=6))
            setSubCategoryNumber(9);
        else
            setSubCategoryNumber(8)
        setPage(value);
    }; 
    useEffect(() => {
        let isMounted = true; 
        const changePaginationItems = ()=>{
        if(window.innerWidth <(601))
            {   
                setPaginationItems(6);
                setPaginationSize("small");
                if(type && pagination)
                    setSubCategoryNumber(9)
            }
        else
            {   
                setPaginationItems(8);
                setPaginationSize("large");
                if(type && pagination)
                    setSubCategoryNumber(8)
            } 
    }     
        if(isMounted)
            { 
                window.addEventListener('resize', changePaginationItems);
                changePaginationItems();
            }
        return () => {
            isMounted = false;
            window.removeEventListener('resize', changePaginationItems);
        };
    }, [type,pagination]); 

    return (
        <div className={`products__container-size num-${subCategoryNumber}`}>
            <div className={`products__main-panel-container`}>
                <div className="products__main-panel">
                    {!list ? <div className="products__list">
                        {ProductsData.map((product, index) => {
                            return <div key={product.id} className="products__category">
                                        <img className="products__category-image" src={RWStorageURL + product.categoryPicture + "?" + SasToken} onClick={(e) => categoryClickHandle(e, index)} alt={`${product.categoryName} pic`} />
                                        <p className="products__category-name">{product.categoryName}</p>
                                    </div>
                        })}
                    </div>: ""}
                    {list ? <div className="products__category-bar">
                        <ul>
                            <li className={`products__category-bar__items`} onClick={listClickHandle}>Products</li>
                            <li>&nbsp;&#62;&nbsp;</li>
                            <li className={`products__category-bar__items ${!type ? "active" : ""}`} onClick={typeBackClickHandle}>{ProductsData[category].categoryName}</li>
                            {type ? <><li>&nbsp;&#62;&nbsp;</li><li className={`products__category-bar__items active`}>{ProductsData[category]["categoryData"][typeIndex]["type"]}</li></> : ""}
                        </ul>
                    </div> : ""}

                    {list ? <div className="products__category-panel">
                        <div className="products__category-panel__container">
                            {!type ? ProductsData[category]["categoryData"].map((typeData, index) => {
                                return <div key={typeData.type} className="products__category-panel__type">
                                            <div className="products__category-panel__type__box"><img className="products__category-panel__type__box__image" src={RWStorageURL + typeData.typePicture + "?" + SasToken} alt="spec" onClick={(e) => typeClickHandle(e, index)} /></div>
                                            <div className="products__category-panel__type__name" onClick={(e) => typeClickHandle(e, index)}><p>{typeData.type}</p></div>
                                        </div>
                            }) : null
                            }
                            {
                                <>
                                    {type ? <div className="products__category-panel__type__items">
                                        {
                                            pagination ? ProductsData[category]["categoryData"][typeIndex]["itemData"].slice((page - 1) * paginationItems, page * paginationItems).map((item, index) => {
                                                return <div key={item.itemName} className="products__category-panel__type__item-container">
                                                    <div className="products__category-panel__type__item">
                                                        <h1 className="products__category-panel__type__item__name">{item.itemName}</h1>
                                                        <div className="products__category-panel__type__item__info">
                                                            <div className="products__category-panel__type__item__image-container">
                                                            <img className="products__category-panel__type__item__image" src={RWStorageURL + item.itemPicture} alt="spec" />                                                    
                                                            </div>
                                                            <div className="products__category-panel__type__item__spec">
                                                                <p>View detailed specifications:</p>
                                                                <a className="products__category-panel__type__item__spec-pdf" target="_blank" rel="noopener noreferrer" href={RWStorageURL + item.itemSpecUrl + "?" + SasToken}><img src={process.env.PUBLIC_URL + "/icons/pdf.svg"} alt="pdf pic" /></a>
                                                            </div>
                                                        </div>
                                                        <p className="products__category-panel__type__item__part-number">Part # prefix: {item.itemNumbers[0].split('-',1)[0].replace(/[0-9]/g, '')}</p>
                                                    </div>
                                                    
                                                </div>
                                            }) :
                                                ProductsData[category]["categoryData"][typeIndex]["itemData"].map((item, index) => {
                                                    return <div key={item.itemName} className="products__category-panel__type__item-container"> 
                                                        <div className="products__category-panel__type__item">
                                                            <h1 className="products__category-panel__type__item__name">{item.itemName}</h1>
                                                            <div className="products__category-panel__type__item__info">
                                                                <div>
                                                                <img className="products__category-panel__type__item__image" src={RWStorageURL + item.itemPicture + "?" + SasToken} alt="spec" />
                                                                </div>
                                                                <div className="products__category-panel__type__item__spec">
                                                                    <p>View detailed specifications:</p>
                                                                    <a className="products__category-panel__type__item__spec-pdf" target="_blank" rel="noopener noreferrer" href={RWStorageURL + item.itemSpecUrl + "?" + SasToken}><img src={process.env.PUBLIC_URL + "/icons/pdf.svg"} alt="pdf pic" /></a>
                                                                </div>
                                                            </div>
                                                            <p className="products__category-panel__type__item__part-number">Part # prefix: {item.itemNumbers[0].split('-',1)[0].replace(/[0-9]/g, '')}</p>
                                                        </div>
                                                    </div>
                                                })
                                        }
                                        {/* Count is total pages. Page is the current page. */}
                                        {type & pagination & ProductsData[category]["categoryData"][typeIndex]["itemData"].length>paginationItems ? <Pagination className="products__pagination" size={paginationSize} count={Math.ceil(ProductsData[category]["categoryData"][typeIndex]["itemData"].length / paginationItems)} page={page} onChange={handleChange} defaultPage={6} siblingCount={0} showFirstButton showLastButton /> : ""}
                                    </div> : null} 
                                                            
                                </>
                            }
                        </div>
                    </div>: ""}
                </div>
            </div>
        </div>
    )
}
