import React, { useState, useEffect} from 'react';
import SectionBar from './SectionBar';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const images = [
  {
    label: 'Electro-Cables',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Electro-Cables.png`
  },
  {
    label: 'Nexans',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Nexans.png`
  },
  {
    label: 'Northern-Cables',
    imgPath:`${process.env.PUBLIC_URL}/icons/vendors/Northern-Cables.png`
  },
  {
    label: 'PTI',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/PTI.png`
  },
  {
    label: 'Service-Wire',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Service-Wire.png`
  },
  {
    label: 'Shawcor',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Shawcor.png`
  },
  {
    label: 'Southwire',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Southwire.png`
  },
  {
    label: 'Appleton',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Appleton.png`
  },
  {
    label: 'Hubbell',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Hubbell.png`
  },
  {
    label: 'ILSCO',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/ILSCO.png`
  },
  {
    label: 'TE-Connectivity',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/TE-Connectivity.png`
  },
  {
    label: 'Techspan',
    imgPath: `${process.env.PUBLIC_URL}/icons/vendors/Techspan.png`
  } 
];
export default function Vendors() {
  const [slidesNum, setSlidesNum] = useState(6);
  const settings = {
      infinite: true,
      slidesToShow: slidesNum,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000
    };
  const changeSlidesNum = ()=>{   
    if(window.innerWidth <(425))
      setSlidesNum(3)
    if((window.innerWidth >= (425) && window.innerWidth <(766)))
      setSlidesNum(4)
    if(window.innerWidth >= (767))
        setSlidesNum(6)   
  }
  
    useEffect(() => {
      let isMounted = true;
      if(isMounted){
        window.addEventListener('resize', changeSlidesNum);
        changeSlidesNum();
      }
      return () => {
          isMounted = false;
          window.removeEventListener('resize', changeSlidesNum);
      };
  }, []);
  return (
    <div className="vendors">
      <div className="vendors__main-panel">
        <div className="vendors__bg-image"></div>
        <SectionBar section="featured vendors" />  
        <div className="vendors__container">  
          <div className="vendors__slider-container">
            <Slider  {...settings} className="vendors__slider-content">
              {
                images.map(image=>
                <div key={image.label} className="vendors__slider-content">
                  <img className="vendors__slider-image" src={image.imgPath} alt={image.label} />
                </div>)
              }         
            </Slider>        
          </div>
        </div>  
      </div>
    </div>
  );
}


