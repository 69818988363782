import React, {useState, createRef} from 'react';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
export default function ContactsEMailDialog(props) {
  const {SiteKey, email, address, city, handleMapPopoverClose}=props;
  const [open, setOpen] = useState(true);
  const [valid, setValid] = useState(false);
  const [submissionWarning, setSubmissionWarning] = useState("");
  const recaptchaRef = createRef();

  const handleClose = () => {
    setOpen(false);
    handleMapPopoverClose();
  };
  const handleSubmit =(event) =>{
    event.preventDefault();
    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      subject: event.target.subject.value,
      message: event.target.message.value,
      branch: {city,address,email}
    };
    if(valid){
       axios.post("/api/Contacts/sendEmail",{data}).then(response=>{
         event.target.reset();
         recaptchaRef.current.reset();
         setValid(false);
         handleClose();
   })
    }
    else{
      setSubmissionWarning("*Please make sure to check the validation and resubmit!")
    }
}
  const onChange =(value) =>{
    const data={
      token:value
    }
   axios.post("/api/Contacts/validation",{data}).then(response=>{
     if(response.data.success) setValid(true)
     else setValid(false);
   })
}

  return (
    <div>
      <Dialog
      className="contacts__dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"      
      >
        <DialogTitle id="responsive-dialog-title" className="contacts__dialog__title-container">
          <div className="contacts__dialog__title-bar">
            <p>Location:</p>            
            <Button className="contacts__dialog__title-close" onClick={handleClose}>
              Close
            </Button>
          </div>
          <div className="contacts__dialog__title-location">
            <p>{`${address.part1}`}</p>
            <p>{`${address.part2}`}</p>
          </div>
        </DialogTitle>
        <DialogContent className="contacts__dialog__content">      
         <div>
        <form className="contacts__dialog__contact-form" onSubmit={(event) =>handleSubmit(event)}>
        <TextField
          required
          className="contacts__dialog__contact-form__field"
          id="outlined-required"
          label="Full Name"
          name="name"
          placeholder="Your name"
        />
        <TextField
          required
          className="contacts__dialog__contact-form__field"
          id="outlined-required"
          label="EMail"
          name="email"
          type="email"
          placeholder="Your Email"
        />
        <TextField
          required
          className="contacts__dialog__contact-form__field"
          id="outlined-required"
          label="Subject"
          name="subject"
          placeholder="Subject"
        />
        <TextField
          required
          id="outlined-multiline-static"
          className="contacts__dialog__contact-form__field--message"
          label="Message"
          name="message"
          multiline
          rows={6}
        />
        <div  className="contacts__dialog__contact-form__field--validation">
        <ReCAPTCHA
         size="compact"
         sitekey={SiteKey}
         onChange={onChange}
         name="validation"
         ref={recaptchaRef}
        />
        <Button  className={`contacts__dialog__contact-form__field--submit-button ${valid? "valid":""}`} type="submit">Submit</Button>
        </div>
        {!valid?<p>{submissionWarning}</p>:""}
        </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  
  );
}
