import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useRoutes} from "react-router-dom";
import "./styles/main.css";
import Home from "./components/Home";
import Products from "./components/Products";
import Resources from "./components/Resources";
import Contacts from "./components/Contacts";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import LinearProgress from '@material-ui/core/LinearProgress';

function App() {
   const RWStorageURL= "https://readywire.blob.core.windows.net/";
    const RWDataURL = "/api/APIData";
    const ProductsURL = "/api/Products";
    const ContactsURL = "/api/Contacts";
    const ResourcesURL = "/api/Resources";
    const [isLoading, setLoading] = useState(true);
    const [APIData, setAPIData] = useState("");
    const [ProductsData, setProductsData] = useState(null);
    const [ContactsData, setContactsData] = useState(null);
    const [ResourcesData, setResourcesData] = useState(null);
    
    useEffect(() => {
      let isMounted = true;
      const fetchData = async () => {
        await Promise.all([
        axios.get(RWDataURL).then(response=>setAPIData(response.data)),
        axios.get(ProductsURL).then(response=>setProductsData(response.data)),
        axios.get(ContactsURL).then(response=>setContactsData(response.data)),
        axios.get(ResourcesURL).then(response=>setResourcesData(response.data))
        ])
        if (isMounted) setLoading(false);   
        }
        fetchData();
      return () => {
        isMounted = false;
      };
    },[]); 
  let routes = useRoutes([
    { path: "/", element: <Home /> },  
    { path: "/products", element: <Products SasToken={APIData.StorageSpecsSasToken} ProductsData={ProductsData} RWStorageURL={RWStorageURL}/> },
    { path: "/resources", element: <Resources SasToken={APIData.StorageResourcesSasToken} ResourcesData={ResourcesData} RWStorageURL={RWStorageURL}/> },
    { path: "/contacts", element: <Contacts APIData={APIData} ContactsData={ContactsData} /> },
  ]);
if (isLoading) return <LinearProgress />
  return (
    <div>
      <Nav SasToken={APIData.StorageSpecsSasToken} ProductsData={ProductsData} RWStorageURL={RWStorageURL}/>
      <div className="app-body">{routes}</div>      
      <Footer />  
    </div>
  );
}
export default App;
