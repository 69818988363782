import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pagination from '@material-ui/lab/Pagination';
export default function SearchDialog({SasToken, searchDialogStatus, setSearchDialogStatus, searchResult, searchInput, RWStorageURL}) { 
  const [page, setPage] = useState(1);
  const [paginationItems, setPaginationItems] = useState(4);
  const [paginationSize, setPaginationSize] = useState("large");

  const handleClose = () => {
    setSearchDialogStatus(false);
    setPage(1);
  }; 
  const handleChange = (event, value) => {
        setPage(value);
  }; 
  const changePaginationItems = ()=>{   
    if(window.innerWidth <(601)|| document.documentElement.clientWidth <(601)
        || document.body.clientWidth<(601))
        {   setPaginationItems(2);
            setPaginationSize("small");
        }
        else
        {   setPaginationItems(4);
            setPaginationSize("large");
        } 
    }

  useEffect(() => {
    let isMounted = true;
    if(isMounted)
    {
      window.addEventListener('resize', changePaginationItems);
      changePaginationItems();
    }
    return () => {
          isMounted = false;
          window.removeEventListener('resize', changePaginationItems);
    };
  }, []);

  return (
      <Dialog
      className="search__dialog"
      disableScrollLock={ true }
      open={searchDialogStatus}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title" className="search__dialog__title-container">
          <div className="search__dialog__title-bar">
            <p className="search__dialog__title" >Search Result for:</p>
            <Button className="search__dialog__title-close" onClick={handleClose}>
              Close
            </Button>
          </div>
          <p className="search__dialog__title" >{searchInput}</p>
        </DialogTitle>              
        <DialogContent className="search__dialog__content">
          <div className="search__category-panel__type__items">
            {
              searchResult.slice((page - 1) * paginationItems, page * paginationItems).map((item, index) => {
                  return <div key={item.itemName+index} className="search__category-panel__type__item-container">
                      <div  className="search__category-panel__type__item">
                          <h1 className="search__category-panel__type__item__name">{item.itemName}</h1>
                          <div className="search__category-panel__type__item__info">
                              <div>
                                <img className="search__category-panel__type__item__image" src={RWStorageURL + item.itemPicture + "?" + SasToken} alt="spec" />                                
                              </div>
                              <div className="search__category-panel__type__item__spec">
                                  <p>View detailed specifications:</p>
                                  <a className="search__category-panel__type__item__spec-pdf" target="_blank" rel="noopener noreferrer" href={RWStorageURL + item.itemSpecUrl + "?" + SasToken}><img src={process.env.PUBLIC_URL + "/icons/pdf.svg"} alt="pdf pic" /></a>
                              </div>
                          </div>
                          <p>Part # prefix: {item.itemNumbers[0].split('-',1)[0].replace(/[0-9]/g, '')}</p>
                          <p className="search__category-panel__type__item__found">*Found by <span>{item.found}</span> </p>
                          <p className="search__category-panel__type__item__found">*From <span>{item.foundCategory}</span> </p>
                      </div>
                  </div>
              })   
            }
            {/* Count is total pages. Page is the current page. */}
          </div>
            {searchResult.length>2 ? <Pagination className="search__pagination" size={paginationSize} count={Math.ceil(searchResult.length / paginationItems)} page={page} onChange={handleChange} defaultPage={Math.ceil((searchResult.length / paginationItems)/2)} siblingCount={0} showFirstButton showLastButton />:""}                                                      
        </DialogContent>        
      </Dialog>
  );
}
