import React from 'react'
export default function SectionBar({section}) {
    return (
        <div className={`section-bar__header`}>        
                <div className={`section-bar__lines`}>
                     <div className={`section-bar__box`}></div>
                    <div className={`section-bar__lines-left`}></div>
                    <h2  className={`section-bar__title`}>{section}</h2>
                    <div className={`section-bar__lines-right`}></div>
                    <div className={`section-bar__box`}></div>
                </div>
            </div>
    )
}
