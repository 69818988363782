import React, {useRef} from "react";
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import About from "./About";
import Values from "./Values";
import Services from "./Services";
import Vendors from './Vendors';
export default function Home() { 
    let arrowDownRef = useRef();
    function handleClickDown() {
        arrowDownRef.current.scrollIntoView({ behavior: "smooth" });
    }
    return (
        <div className="home" > 
            <section className="home__about">           
                <About />
                <div className="home__arrow-icon">
                    <ArrowDownIcon  className="home__arrow-icon--down" onClick={handleClickDown}/>
                </div>
            </section>            
            <section className="home__info-container" ref={arrowDownRef}>
                <div className="home__info">  
                    <Values />                  
                    <Services />
                    <Vendors />
                </div>                
            </section>          
        </div>
    )
}
