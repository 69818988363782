import React, { useState} from 'react';
export default function Resources({SasToken, ResourcesData,RWStorageURL}) {
  const [type, setType] = useState(false);
  const [typeIndex, setTypeIndex] = useState(0);

  const typeClickHandle = (e, index) => {
    setType(true);        
    setTypeIndex(index);     
  }
  const typeBackClickHandle = () => {
    setType(false);
    setTypeIndex(0);
  }
  return (
    <div className="resources"> 
      <div className="resources__main-panel-container">
        <div className="resources__main-panel">
          <div className="resources__cards-container"> 
              <div className="resources__category-bar">
                <ul>
                    <li className={`resources__category-bar__items ${!type ? "active" : ""}`} onClick={typeBackClickHandle}>Resources</li>
                    <li>&nbsp;&#62;&nbsp;</li>
                    {type?<li className={`resources__category-bar__items ${type ? "active" : ""}`} >{ResourcesData[typeIndex].type}</li>:""}                    
                </ul>
              </div>
            {!type? <div className="resources__cards">
              {ResourcesData.map((resource,index)=>{
                    return(<div key={resource.id} className="resources__card-container" onClick={(e) => typeClickHandle(e, index)}>
                            <div className="resources__card type">
                                <div className="resources__card--box type">
                                    <div className="resources__card--title-container">
                                        <div className="resources__card--line"></div>
                                        <img className="resources__card--icon type" src={RWStorageURL + resource.image + "?" + SasToken} alt={resource.type}/><div className="resources__card--line"></div>
                                    </div>                                
                                        <p className="resources__card--text">{resource.type}</p>                   
                                </div>
                            </div>
                            
                        </div>
              )})  }
            </div>:""}            
            {type ? <>
              <div className="resources__cards">
                 { ResourcesData[typeIndex].data.map((item,index)=>
                    <a key={item.url} className="resources__card-container" target="_blank" rel="noopener noreferrer" href={RWStorageURL + item.url + "?" + SasToken}>
                      <div className="resources__card">
                        <div className="resources__card--box">
                            <div className="resources__card--title-container">
                                <div className="resources__card--line"></div>
                                <img className="resources__card--icon" src={RWStorageURL + item.image + "?" + SasToken} alt={item.name}/><div className="resources__card--line"></div>
                            </div>                                
                            <p className="resources__card--text">{item.name}</p>                   
                        </div>
                      </div>
                    </a>
                  )
                }              
              </div>            
            </>: ""}
          </div>    
        </div>
      </div>
    </div>
  );
}
