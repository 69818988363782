import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContactsMap from "./ContactsMap";
export default function ContactsMapDialog(props) {
  const {APIKey, location, address, handleMapPopoverClose, google}=props;
  const [open, setOpen] = useState(true);
  const [viewMap, setViewMap] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setViewMap(false);
    handleMapPopoverClose();
  };
  const handleViewMap = () => {
    setViewMap(true);
  };
  return (
    <div>
      <Dialog
      className="contacts__dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"      
      >
        <DialogTitle id="responsive-dialog-title" className="contacts__dialog__title-container">
          <div className="contacts__dialog__title-bar">
            <p>Location:</p>            
            <Button className="contacts__dialog__title-close" onClick={handleClose}>
              Close
            </Button>
          </div>
          <div className="contacts__dialog__title-location">
            <p>{`${address.part1}`}</p>
            <p>{`${address.part2}`}</p>
          </div>
        </DialogTitle>
        <DialogContent className="contacts__dialog__content">
        {!viewMap ? <Button className="contacts__dialog__view-button" onClick={handleViewMap}>View the map</Button>:""}
                    {viewMap?<ContactsMap
                    google={google}
                    center={{ lat: location.lat, lng: location.lng }}
                    address={address}
                    zoom={15}
                    APIKey={APIKey}
                    />
        :""}                
        </DialogContent>
      </Dialog>
    </div>
  );
}
