import React, {useState, useEffect} from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import SearchDialog from "./SearchDialog";

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    height:'90%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  iconButton:{
    marginLeft: theme.spacing(1),
    padding:theme.spacing(2),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    //paddingLeft: `calc(1em + ${theme.spacing(.5)}px)`,
    paddingTop: `${theme.spacing(.5)}px`,
    color:"white",
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(.5em + ${theme.spacing(.1)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
export default function Search({SasToken, ProductsData, RWStorageURL}) {
    const classes = useStyles();
    const [searchInput, setSearchInput]= useState("");
    const [searchResult, setSearchResult]= useState([]);
    const [searchDialogStatus, setSearchDialogStatus]= useState(false);
    const [alertDialogStatus, setAlertDialogStatus]= useState(false);
    const [alertMessage, setAlertMessage]= useState("");

    const handleCloseAlertDialog = () =>{
        setAlertDialogStatus(false);
        setAlertMessage("");
    }
    const handleSearch = () =>{
        if(searchInput===""){
          setAlertDialogStatus(true);
          setAlertMessage("Please enter the part number!")
        }
        else 
        {
          const searchArray=[];
          ProductsData.forEach((product)=>
            product.categoryData.forEach(category=>
              { if(category.type.toLowerCase().includes(searchInput.toLowerCase()))
                  {
                    category.itemData.forEach(item=>{
                      item.found=`Type in ${category.type}`;
                      item.foundCategory=`${product.categoryName}`;
                      searchArray.push(item)
                    });
                  }
                else
                    category.itemData.forEach(item=>
                    { if(item.itemName.toLowerCase().includes(searchInput.toLowerCase())) 
                          { item.found=`Name in ${category.type}`;
                            item.foundCategory=`${product.categoryName}`;
                            searchArray.push(item);
                          }
                      else
                      item.itemNumbers.every(itemNumber=>{
                        if(itemNumber.toLowerCase().includes(searchInput.toLowerCase())) 
                        { item.found=`Part # in ${category.type}`;
                          item.foundCategory=`${product.categoryName}`;
                          searchArray.push(item);
                          return false;
                        }
                        return true;
                        })
                    })
              })
          )
          if(searchArray.length===0){
            setAlertDialogStatus(true);
            setAlertMessage(`Sorry, no result found for ${searchInput}!`)
          }
          else{
            setSearchResult(searchArray);
            setSearchDialogStatus(true);
          }
        }
    }
    useEffect(() => {
      let isMounted = true;
      if(isMounted)
      {
        if(!searchDialogStatus)
          setSearchInput("");
      }
      return () => {
          isMounted = false;
      }
    }, [searchDialogStatus]);
    return(
        <div className={classes.search}>            
            <Snackbar
            className="search__snackbar"
            anchorOrigin={{ 'vertical':'top', 'horizontal':'right' }}
            open={alertDialogStatus}
            autoHideDuration={8000}
            onClose={handleCloseAlertDialog}
            key={{ 'vertical':'top', 'horizontal':'right' }}
            >
              <Alert className="search__snackbar__alert" onClose={handleCloseAlertDialog} severity="warning">
                  {alertMessage}
              </Alert>
            </Snackbar>
            <IconButton
            type="button"
            className={classes.iconButton} 
            onClick={handleSearch}
            aria-label="search">
              <div className={classes.searchIcon} >                  
              <SearchIcon />                
              </div>
            </IconButton>
            <InputBase
            placeholder="Search"
            value={searchInput}
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
                onKeyPress={(event) => {
                if (event.key === "Enter") {
                handleSearch()
                }
            }}
            onChange={(event)=>setSearchInput(event.target.value)}
            />
            {searchDialogStatus? <SearchDialog SasToken={SasToken} searchDialogStatus={searchDialogStatus} setSearchDialogStatus={setSearchDialogStatus} searchResult={searchResult} searchInput={searchInput} RWStorageURL={RWStorageURL}/>:""}
        </div>            
    )
  }