import React, { useState, useEffect} from 'react';
import SectionBar from "./SectionBar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
export default function Services() {
    const [pagination, setPagination] = useState(false);   
    const valuesData= 
    [
        { "text":"Our experienced sales team delivers a superior and personalized service offering coupled with expansive product knowledge to meet your needs.",
          "title":"EXPERIENCE",
          "src":`${process.env.PUBLIC_URL}/icons/services/experience.png`
        },
        { "text":"Our strategic partnerships allow us to provide our customers with access to industry competitive pricing, quality products, reliable deliveries and technical support services.",
          "title":"PARTNERSHIPS",
          "src":`${process.env.PUBLIC_URL}/icons/services/partnerships.png`
        },
        { "text":"Our warehouse services are uniquely positioned to facilitate rapid and efficient deliveries, service and support to our customers.",
          "title":"EFFICIENCY",
          "src":`${process.env.PUBLIC_URL}/icons/services/efficiency.png`
        }
    ]

    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };  

    const addPagination = ()=>{   
    if(window.innerWidth <(766))
      setPagination(true)
      else
      setPagination(false)
    }
  
    useEffect(() => {
        let isMounted = true;
        if(isMounted)
        {   window.addEventListener('resize', addPagination);
            addPagination();
        }
        return () => {
          isMounted = false;
          window.removeEventListener('resize', addPagination);
        };
    }, []);
    return (
        <div className="values">
            <section className="values__intro" >
                <SectionBar section="What We Bring" />                 
                {pagination ?
                <div className="values__cards-slider-container">
                     <Slider  {...settings} className="values__cards">
                         
                        {valuesData.map((item,index)=>{               
                            return(
                                <div key={item.title}>
                                    <div  className="values__card-container">
                                        <div className="values__card">
                                            <div className="values__card--box">
                                                <div className="values__card--title-container">
                                                    <div className="values__card--line"></div>                                
                                                    <img className="values__card--icon" src={item.src} alt={item.title}/><div className="values__card--line"></div>
                                                </div>
                                                <div>
                                                    <h4 className="values__card--title">{item.title}</h4>
                                                    <p className="values__card--text">{item.text}</p> 
                                                </div>                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )})}
                        
                    </Slider> 
                    </div>    
                :<div className="values__cards">
                    {valuesData.map((item,index)=>{               
                        return(
                            <div key={item.title} className="values__card-container">
                                <div className="values__card">
                                    <div className="values__card--box">
                                        <div className="values__card--title-container">
                                            <div className="values__card--line"></div>                                
                                            <img className="values__card--icon" src={item.src} alt={item.title}/><div className="values__card--line"></div>
                                        </div>
                                        <div>
                                            <h4 className="values__card--title">{item.title}</h4>
                                            <p className="values__card--text">{item.text}
                                        </p> 
                                        </div>                
                                    </div>
                                </div>
                            </div>
                    )})}
                </div> 
                }
            </section>
        </div>
    )
}
