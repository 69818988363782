import React from 'react';
import ContactsPopover from './ContactsPopover';
export default function Contacts({APIData, ContactsData}){
    return (
        <div className="contacts">
            <div className="contacts__cards-container">
                <div className="contacts__cards">
                    {ContactsData.map((contact)=>
                        <div key={contact.id} className="contacts__card-container">
                            <div key={contact.t} className="contacts__card">
                                <div className="contacts__card--box">
                                    <div className="contacts__card--item name-container">
                                        <div className="contacts__card--item line"></div>
                                        <p className="contacts__card--item name">{contact.name}<br/>
                                        {contact.city}</p>
                                        <div className="contacts__card--item line"></div>
                                    </div>
                                    <div className="contacts__card--item icon-container">                
                                        <div>
                                            <p className="contacts__card--item">{contact["address-part1"]}</p>
                                            <p className="contacts__card--item">{contact["address-part2"]}</p>
                                        </div>                   
                                    </div>                
                                    <ul className="contacts__card--item phone-address">
                                        <li><ContactsPopover content="phone" contentPopover={<><p>Phone: {contact.phone}</p><p>Toll Free:{contact.tf}</p></>} /> </li>|
                                        <li><ContactsPopover content="mail" contentPopover={{"SiteKey":APIData.SiteKey, "email":contact.email, "address":{"part1":contact["address-part1"], "part2":contact["address-part2"]},"city":contact.city}} /></li>|
                                        <li><ContactsPopover content="map" contentPopover={{"APIKey":APIData.APIKey, "location":contact.location, "address":{"part1":contact["address-part1"], "part2":contact["address-part2"]}}} /></li>
                                    </ul>
                                </div>
                            </div> 
                        </div> 
                     )} 
                </div>              
            </div>                
        </div>
    )
}

