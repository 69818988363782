export default function About() {
    return (
        <div className="about">
            {/* <div className="about__image"></div>              */}
            <section className="about__intro">
                <div  className="about__services__card-container">
                    <div className="about__services__card">
                        <div className="about__services__card--box">
                            <div className="about__services__card--title-container">
                                <div className="about__services__card--line"></div>                                
                                <div className="about__services__card--line"></div>
                            </div>
                            <div>
                              <h1 className="about__intro__title">Who We Are</h1> 
                              <p className="about__intro__description"><span>ReadyWire </span>is a family owned, full line distributor of wire and cable focused on the demands of the redistribution market in Canada. 
                              We are the only fully Canadian owned redistribution company in Canada! 
                              With some of the most knowledgeable and experienced staff in the industry, 
                              we provide superior service in a timely manner. 
                              We draw from vast inventory resources nationwide and can accommodate on demand shipping. 
                              By eliminating the need for multiple vendors, ReadyWire reduces your inventory investment and adds value to your bottom line!
                              </p>
                            </div>                
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
