export default function Footer() {
    return (
        <div className="footer" >
                <ul className="footer__container">
                    <li className="footer__container--flag"><img src={process.env.PUBLIC_URL+"/icons/canadian-flag.svg"} alt="flag" /></li>
                    <li className="footer__container--city">Vancouver</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Victoria</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Edmonton</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Calgary</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Winnipeg</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Toronto</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Hamilton</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Montreal</li>
                    <li className="footer__container--icon"></li>
                    <li className="footer__container--city">Halifax</li>
                    <li className="footer__container--line">                        |
                    </li>
                    <li className="footer__container--logo"><span>ReadyWire.</span>CA</li>                    
                </ul>
        </div>
    )
}
