import React, { useState, useEffect} from 'react';
import SectionBar from "./SectionBar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
export default function Services() {
    const [pagination, setPagination] = useState(false);
    const servicesData= 
    [
        { "text":"CUTTING AT NO EXTRA COST",
          "src":`${process.env.PUBLIC_URL}/icons/services/cutting-no-extra-cost.png`
        },
        { "text":"TAGGING & LABELLING",
          "src":`${process.env.PUBLIC_URL}/icons/services/tagging-and-labelling.png`
        },
        { "text":"PARALLELING & CABLING",
          "src":`${process.env.PUBLIC_URL}/icons/services/paralleling-and-cabling.png`
        },
        { "text":"PERSONABLE TECHNICAL SUPPORT",
          "src":`${process.env.PUBLIC_URL}/icons/services/personable-tech-support.png`
        }        
    ] 
     const settings = {
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2
    };  
    const addPagination = ()=>{   
    if(window.innerWidth <(766)
            )
      setPagination(true)
      else
      setPagination(false)
    }
  
    useEffect(() => {
        let isMounted = true;
        if(isMounted)
        {   window.addEventListener('resize', addPagination);
            addPagination();
        }
        return () => {
          isMounted = false;
          window.removeEventListener('resize', addPagination);
        };
    }, []);
    return (
        <div className="services">
            <section>
                <SectionBar section="Our Services" />                     
                {!pagination ?
                    <section className="services__cards"> 
                        { servicesData.map((item,index)=>{
                            return(
                                <div key={item.text} className="services__card-container">                             
                                    <div className="services__card">
                                        <div className="services__card--box">
                                            <div className="services__card--title-container">
                                                <div className="services__card--line"></div>
                                                <img className="services__card--icon" src={item.src} alt={item.text}/><div className="services__card--line"></div>
                                            </div>  
                                            <h4 className="services__card--title">{item.text}</h4>
                                        </div>
                                    </div>
                                </div>
                            )})}
                    </section>
                    :
                    <div className="services__cards-slider-container">
                        <Slider  {...settings} >
                            {servicesData.map((item,index)=>{
                                return(
                                    <div key={item.text} className="services__card-container">                             
                                        <div className="services__card">
                                            <div className="services__card--box">
                                                <div className="services__card--title-container">
                                                    <div className="services__card--line"></div>
                                                    <img className="services__card--icon" src={item.src} alt={item.text}/><div className="services__card--line"></div>
                                                </div>  
                                                <h4 className="services__card--title">{item.text}</h4>
                                            </div>
                                        </div>
                                    </div>
                                )})
                            }
                        </Slider>
                    </div>
                }
            </section>
        </div>
    )
}
