import React, {useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Search from './Search';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import NavMenu from './NavMenu';
export default function Nav({SasToken, ProductsData, RWStorageURL}) {
  const [navbar, setNavbar]= useState(false);
  const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 8000
    };
    
  const texts = [
    "A full-line wire and cable, specifically serving the re-distribution market.",
    "provides a portfolio with an unmatched variety and scope of products.",
    "has exceptional value-added services that lower your inventory investment and increase your returns.",
    "offers unparalleled customer service and adds value to the bottom line."
  ]

  const changeNavbarBackground = ()=>{
    if(window.innerWidth >(766))
    {
      if (window.scrollY >= (80) && window.innerWidth >(766))
        setNavbar(true);
      else
        setNavbar(false)
    }
  }
  useEffect(() => {
    let isMounted = true;
    if(isMounted)
    {
      window.addEventListener('scroll', changeNavbarBackground);
    }
    return () => {
        isMounted = false;
        window.removeEventListener('scroll', changeNavbarBackground);
    };
  }, []);
  return (
    <nav className={navbar?"navbar scroll-active": "navbar"}>            
      <div className="navbar__logo-container">
        <div className={navbar?"navbar__logo scroll-active":"navbar__logo"}></div>        
        {!navbar? <div className="navbar__logo-text">
          <div className="navbar__slider-container">
            <Slider  {...settings} className="navbar__slider-content">
              {
                texts.map((text,index)=>
                  <div key={index} className="navbar__slider-content">
                    <p className="navbar__slider-content__text">{text}</p>
                  </div>
                )
              }         
            </Slider>        
          </div>
        </div>
        :<p className="navbar__text">A full-line wire and cable distributor</p>}
      </div>
      <div className="navbar__links">
        <ul className="navbar__items"> 
          <li className="navbar__item">
            <NavLink 
              exact="true"            
              to="/"
              >About Us
            </NavLink>
          </li>              
          <li className="navbar__item">
            <NavLink 
              exact="true"            
              to="/products"
              >Products
            </NavLink>
          </li>            
          <li className="navbar__item">
            <NavLink 
              exact="true"          
              to="/resources"
              >Resources
            </NavLink>
          </li>           
          <li className="navbar__item">
            <NavLink 
              exact="true"            
              to="/contacts"
              >Contacts
            </NavLink>
          </li>
          <li className="navbar__item-search">
              <Search SasToken={SasToken} ProductsData={ProductsData} RWStorageURL={RWStorageURL} />
          </li>
          <li className="navbar__item-menu">
            <NavMenu /> 
          </li>
        </ul>
      </div>                       
    </nav>
  )}
