import React, {useState} from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/MailOutline';
import ContactsMapDialog from './ContactsMapDialog';
import ContactsEMailDialog from './ContactsEMailDialog';
export default function ContactsPopover({content, contentPopover}) {
  const [paperBGColor, setPaperBGColor] = useState("#e46242");
  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
      overflow: 'hidden',
    },
    paper: {
      padding: theme.spacing(1.5),    
      backgroundColor: paperBGColor,
      color:"white" 
    },  
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  

  const handlePopoverOpen = (event) => {
    if(content!=="phone") setPaperBGColor("transparent");
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {    
    if(content==="phone")    
      setAnchorEl(null);
  };
  const handleMapPopoverClose =()=>{
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);
  return (
    <div className="contacts__popover">
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {content==="phone"?<PhoneIcon />:content==="mail"?<MailIcon />:<img className="contacts__popover--icon" src={process.env.PUBLIC_URL+"/icons/map.svg"} alt="Map Icon" />}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={`${classes.popover} contacts__popover--paper`}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock={ true }
        
      >
        {content==="phone"?contentPopover:
          content==="map"?<ContactsMapDialog APIKey={contentPopover.APIKey} location={contentPopover.location} address={contentPopover.address} handleMapPopoverClose={handleMapPopoverClose}/>:
          <ContactsEMailDialog SiteKey={contentPopover.SiteKey} email={contentPopover.email} address={contentPopover.address} city={contentPopover.city} handleMapPopoverClose={handleMapPopoverClose}/>}
      </Popover>
    </div>
  );
}
